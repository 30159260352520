import React, { useEffect, useRef, useState } from "react";
import SidePanelDrawer from "share/atoms/sidepanel-drawer/";
import HamburgerIcon from "assets/HamburgerMenu.svg";
import NavBar from "share/molecules/nav-bar/";
import HamburgerMenu from "share/molecules/hamburger-menu/";
import {
  SideBarIcon,
  RouterWrap,
  Container,
  SignInButton,
  SignUpButton,
  Logo,
} from "./styles";
import CNAPSLogo from "assets/frame.svg";
import CNAPSSchoolLogo from "assets/school-admin/cnaps-school-logo.svg";
import { useLocation, useHistory } from "react-router-dom";
import { useResize } from "share/hooks/useResize";

function Features(props) {
  const { width } = useResize();
  const { location, topPos } = props;
  const history = useHistory();
  const locate = useLocation();
  let url = window.location.href;
  let hostName = url.split("//")[1].split("/")[0];
  url = [url.split("//")[0], hostName].join("//");

  const [showSidebar, setShowSidebar] = useState(false);

  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleSignIn = () => {
    history.push("/school/login");
  };
  const handleSignUp = () => {
    history.push("/school/registration");
  };
  const handleHome = () => {
    history.push("/");
  };
  return (
    <Container $top={topPos}>
      {locate.pathname.includes("school") ? (
        <RouterWrap>
          <Logo
            src={CNAPSSchoolLogo}
            alt="cnaps"
            height="55px"
            onClick={handleHome}
          />
          {width > 800 ? (
            <div>
              <SignInButton onClick={handleSignIn}>Sign In</SignInButton>
              <SignUpButton onClick={handleSignUp}>Sign Up</SignUpButton>
            </div>
          ) : (
            ""
          )}
        </RouterWrap>
      ) : (
        <>
          {showSidebar && (
            <SidePanelDrawer onHide={handleSidebar} isFullWidth>
              <HamburgerMenu handleSidebar={handleSidebar} />
            </SidePanelDrawer>
          )}
          <SideBarIcon>
            <img src={CNAPSLogo} alt="cnaps" style={{ width: "156px" }} />
            <img
              src={HamburgerIcon}
              onClick={handleSidebar}
              alt="hamburger"
              height="32px"
            />
          </SideBarIcon>
          {width > 800 && (
            <RouterWrap>
              <Logo
                src={CNAPSLogo}
                alt="cnaps"
                height="48px"
                onClick={handleHome}
              />
              <NavBar />
            </RouterWrap>
          )}
        </>
      )}
    </Container>
  );
}

export default Features;
