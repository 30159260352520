import ISCO from "assets/isco_updated.svg";
import ISEO from "assets/iseo_updated.svg";
import ISKO from "assets/isko_updated.svg";
import ISMO from "assets/ismo_updated.svg";
import ISSO from "assets/isso_updated.svg";
import JOSA from "assets/updated_josa.svg";
import NTEX from "assets/ntex_updated.svg";
import ROM from "assets/updated2_rom.svg";
//----------------------------------------
import ISMOSyllabus from "assets/files/ExamSyllabus/updated-ISMO.pdf";
import ISSOSyllabus from "assets/files/ExamSyllabus/updated-ISSO.pdf";
import ISKOSyllabus from "assets/files/ExamSyllabus/updated-ISKO.pdf";
import ISEOSyllabus from "assets/files/ExamSyllabus/updated-ISEO.pdf";
import ISCOSyllabus from "assets/files/ExamSyllabus/updated-ISCO.pdf";
import JOSASyllabus from "assets/files/ExamSyllabus/JOSA.pdf";
import NTEXSyllabus from "assets/files/ExamSyllabus/NTEX.pdf";
import ROMSyllabus from "assets/files/ExamSyllabus/ROM.pdf";
// patterns
import ISMOPattern from "assets/files/ExamPatterns/updated-ISMO.pdf";
import ISSOPattern from "assets/files/ExamPatterns/updated-ISSO.pdf";
import ISKOPattern from "assets/files/ExamPatterns/updated-ISKO.pdf";
import ISEOPattern from "assets/files/ExamPatterns/updated-ISEO.pdf";
import ISCOPattern from "assets/files/ExamPatterns/updated-ISCO.pdf";
import JOSAPattern from "assets/files/ExamPatterns/JOSA.pdf";
import NTEXPattern from "assets/files/ExamPatterns/NTEX.pdf";
import ROMPattern from "assets/files/ExamPatterns/ROM.pdf";
//------------------------------------------
import { examData } from "utils/exam.details";

export const allExamsData = [
  {
    id: 1,
    name: "International Standard Mathematics Olympiad",
    icon: ISMO,
    abr: "ismo",
    isBuyNowEnable: true,
    // phase1date: "10/31/2021",
    // phase2date: "01/23/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "10-Nov-24", //13-11-2022
    fiftyOff: true,
  },
  {
    id: 2,
    name: "National Talent Exam ",
    icon: NTEX,
    abr: "ntex",
    isBuyNowEnable: true,
    // phase1date: "12/26/2021",
    // phase2date: "02/20/2022",
    newIcon: true,
    previousYearSample: true,
    class: 6,
    isButtonDisabled: false,
    date: "15-Dec-24", //16-10-2022
  },
  {
    id: 3,
    name: "International Standard Science Olympiad",
    icon: ISSO,
    abr: "isso",
    isBuyNowEnable: true,
    // phase1date: "12/05/2021",
    // phase2date: "01/30/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "01-Dec-24", //20-11-2022
    fiftyOff: true,
  },
  {
    id: 4,
    name: "Regional Olympiad in Mathematics ",
    icon: ROM,
    abr: "rom",
    isBuyNowEnable: true,
    // phase1date: "12/26/2021",
    // phase2date: "02/20/2022",
    newIcon: true,
    previousYearSample: true,
    class: 6,
    isButtonDisabled: false,
    date: "01-Sep-24", //"23-10-2022"
  },
  {
    id: 5,
    name: "Junior Olympiad in Science and Astronomy",
    icon: JOSA,
    abr: "josa",
    isBuyNowEnable: true,
    // phase1date: "12/26/2021",
    // phase2date: "02/20/2022",
    newIcon: true,
    previousYearSample: true,
    class: 6,
    isButtonDisabled: false,
    date: "03-Nov-24", //"06-11-2022"
  },

  {
    id: 6,
    name: "International Standard Knowledge Olympiad",
    icon: ISKO,
    abr: "isko",
    isBuyNowEnable: true,
    // phase1date: "12/19/2021",
    // phase2date: "02/13/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "08-Dec-24", //"29-01-2023",
    fiftyOff: true,
  },
  {
    id: 7,
    name: "International Standard English Olympiad",
    icon: ISEO,
    abr: "iseo",
    isBuyNowEnable: true,
    // phase1date: "12/12/2021",
    // phase2date: "02/06/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "17-Nov-24", //"05-02-2023"
    fiftyOff: true,
  },
  {
    id: 8,
    name: "International Standard Cyber Olympiad",
    icon: ISCO,
    abr: "isco",
    isBuyNowEnable: true,
    // phase1date: "12/26/2021",
    // phase2date: "02/20/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "02-Feb-25", //"12-02-2023"
    fiftyOff: true,
  },
];

export const sixthToTenthExamsData = [...allExamsData];

export const firstToFifthExamsData = [
  {
    id: 1,
    name: "International Standard Mathematics Olympiad",
    icon: ISMO,
    abr: "ismo",
    isBuyNowEnable: true,
    // phase1date: "10/31/2021",
    // phase2date: "01/23/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "10-Nov-24", //13-11-2022,
    fiftyOff: true,
  },
  {
    id: 2,
    name: "International Standard Science Olympiad",
    icon: ISSO,
    abr: "isso",
    isBuyNowEnable: true,
    // phase1date: "12/05/2021",
    // phase2date: "01/30/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "01-Dec-24", //20-11-2022
    fiftyOff: true,
  },
  {
    id: 3,
    name: "International Standard Knowledge Olympiad",
    icon: ISKO,
    abr: "isko",
    isBuyNowEnable: true,
    // phase1date: "12/19/2021",
    // phase2date: "02/13/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "08-Dec-24", //"29-01-2023",
    fiftyOff: true,
  },
  {
    id: 4,
    name: "International Standard English Olympiad",
    icon: ISEO,
    abr: "iseo",
    isBuyNowEnable: true,
    // phase1date: "12/12/2021",
    // phase2date: "02/06/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "17-Nov-24", //"05-02-2023"
    fiftyOff: true,
  },
  {
    id: 5,
    name: "International Standard Cyber Olympiad",
    icon: ISCO,
    abr: "isco",
    isBuyNowEnable: true,
    // phase1date: "12/26/2021",
    // phase2date: "02/20/2022",
    previousYearSample: true,
    class: 1,
    isButtonDisabled: false,
    date: "02-Feb-25", //"12-02-2023"
    fiftyOff: true,
  },
];

export const examsTabData = [
  ...allExamsData,
  {
    id: 9,
    lastCard: true,
  },
];

export const examsCompleteDetails = {
  ismo: {
    name: "International Standard Mathematics Olympiad",
    class: 1,
    // phase1date: "10/31/2021",
    // phase2date: "01/23/2022",
    date: "10-Nov-24", //13-11-2022
    shortName: "ISMO",
    exam_pattern: ISMOPattern,
    exam_syllabus: ISMOSyllabus,
    subject: "Mathematics",
    desc: "International Standard Mathematics Olympiad is the platform where the problems provide the intellectual stimulus and challenge the hidden potentials.",
    // sampleVideo: {
    //   sampleDesc: "Student Evaluation Chart’ to students participating",
    //   sampleVideoUrl: "https://youtu.be/3-WII5cPytg"
    // },
    level2: true,
    details: examData["ismo"],
  },
  isso: {
    name: "International Standard Science Olympiad",
    class: 1,
    // phase1date: "12/05/2021",
    // phase2date: "01/30/2022",
    date: "01-Dec-24", //20-11-2022
    shortName: "ISSO",
    exam_pattern: ISSOPattern,
    exam_syllabus: ISSOSyllabus,
    subject: "Science",
    desc: "International Standard Science Olympiad churns out the innate academic potential in Science and provides uncommon opportunities for learning.",
    // sampleVideo: {
    //   sampleDesc: "Student Evaluation Chart’ to students participating ",
    //   sampleVideoUrl: "https://youtu.be/3-WII5cPytg"
    // },
    level2: true,
    sampleVideoUrl: "https://youtu.be/3-WII5cPytg",
    details: examData["isso"],
  },
  isko: {
    name: "International Standard Knowledge Olympiad",
    class: 1,
    // phase1date: "12/19/2021",
    // phase2date: "02/13/2022",
    date: "08-Dec-24", //"29-01-2023",
    shortName: "ISKO",
    exam_pattern: ISKOPattern,
    exam_syllabus: ISKOSyllabus,
    subject: "Knowledge",
    desc: "CNAPS’s International Standard Knowledge Olympiad (ISKO) tests the general knowledge and awareness in a student. It assesses and measures the student’s ability to connect what is learnt to the outside world. Also, it gAuges the knowledge about the various current events. Out and out an opportunity to participate in ISKO, student’s general awareness, knowledge on current affairs and ability to apply the learning in general to day-to-day life will be showcased.",
    content: {
      isDisableNurture: true,
      assessment: `You will have the assessment of your abilities vis-a-vis other students who are your peers in future entrance/competitive exams`,
      awarenessAndVision: `You will develop an awareness towards your surroundings and start applying logical thinking.`,
    },
    // sampleVideo: {
    //   sampleDesc: "Student Evaluation Chart’ to students participating",
    //   sampleVideoUrl: "https://youtu.be/3-WII5cPytg"
    // },
    details: examData["isko"],
  },
  iseo: {
    name: "International Standard  English Olympiad",
    class: 1,
    // phase1date: "12/12/2021",
    // phase2date: "02/06/2022",
    date: "17-Nov-24", //"05-02-2023"
    shortName: "ISEO",
    exam_pattern: ISEOPattern,
    exam_syllabus: ISEOSyllabus,
    subject: "Knowledge",
    desc: "International Standard English Olympiad is one of its kind which assesses the skill in English language which indirectly measures the general educational achievement. The outcomes of ISEO will help the young learners to shape their future by jostling the 21st Century Skills to next level",
    content: {
      isDisableNurture: true,
      assessment: `You will have the assessment of your abilities vis-a-vis other students who are your peers in future entrance/competitive exams`,
      awarenessAndVision: `You will develop an awareness towards your surroundings and start applying logical thinking.`,
    },
    // sampleVideo: {
    //   sampleDesc: "Student Evaluation Chart’ to students participating ",
    //   sampleVideoUrl: "https://youtu.be/3-WII5cPytg"
    // }
    details: examData["iseo"],
  },
  isco: {
    name: "International Standard Cyber Olympiad",
    class: 1,
    date: "02-Feb-25", //"12-02-2023"
    // phase1date: "12/26/2021",
    // phase2date: "02/20/2022",
    shortName: "ISCO",
    exam_pattern: ISCOPattern,
    exam_syllabus: ISCOSyllabus,
    subject: "Knowledge",
    desc: "International Standard Cyber Olympiad is a unique test whose metrics are helpful in knowing the standards in both active and passive learning of the child in this technology driven times. Basically, ISCO meant to showcase the skill and achievement of the learner in general aptitude and essential know-how of the Information Technology.",
    content: {
      isDisableNurture: true,
      assessment: `You will have the assessment of your abilities vis-a-vis other students who are your peers in future entrance/competitive exams`,
      awarenessAndVision: `You will develop an awareness towards your surroundings and start applying logical thinking.`,
    },
    // sampleVideo: {
    //   sampleDesc: "Student Evaluation Chart’ to students participating ",
    //   sampleVideoUrl: "https://youtu.be/3-WII5cPytg"
    // }
    details: examData["isco"],
  },
  josa: {
    name: "Junior Olympiad in Science and Astronomy",
    class: 6,
    date: "03-Nov-24", //"06-11-2022"
    // phase1date: "12/26/2021",
    // phase2date: "02/20/2022",
    shortName: "JOSA",
    exam_pattern: JOSAPattern,
    exam_syllabus: JOSASyllabus,
    subject: "Knowledge",
    desc: "JOSA- Junior Olympiad in Science and Astronomy is an Olympiad conducted for classes 6th to 10th standard is aimed at bringing the most talented young minds from across the country into a competition of best level in both Science and Astronomy. This Olympiad provides a stimulus to undertake a lifelong journey in learning Science. These Olympiads are not merely competitions, they are the opportunities for the young minds to meet and exchange ideas. JOSA not only excites the scientific curiosity of the students but also enriches school education. This exam paves the way for the most prestigious Junior Science Olympiad and Junior Astronomy Olympiad conducted by HBCSE leading to International Olympiads in Science and Astronomy.",
    content: {
      isDisableNurture: true,
      assessment: `You will have the assessment of your abilities vis-a-vis other students who are your peers in future entrance/competitive exams`,
      awarenessAndVision: `You will develop an awareness towards your surroundings and start applying logical thinking.`,
    },
    // introductionVideo: {
    //   introductionDesc: "Student Evaluation Chart’ to students participating ",
    //   introductionVideoUrl: "https://youtu.be/3-WII5cPytg"
    // },
    // sampleVideo: {
    //   sampleDesc: "Student Evaluation Chart’ to students participating ",
    //   sampleVideoUrl: "https://youtu.be/3-WII5cPytg"
    // },
    level2: true,
    details: examData["josa"],
  },
  ntex: {
    name: "National Talent Exam Olympiad",
    class: 6,
    date: "15-Dec-24", //16-10-2022
    // phase1date: "12/26/2021",
    // phase2date: "02/20/2022",
    shortName: "NTEX",
    exam_pattern: NTEXPattern,
    exam_syllabus: NTEXSyllabus,
    subject: "Knowledge",
    desc: "NTEX- National Talent Exam is a capacity hunt conducted as an assessment test to surface the students with all round academic excellence. NTEX evaluates students in both General Aptitude and Scholastic part of Mathematics, Science and Social Sciences. This exam paves the path for the most venerated exam among the high school students called the NTSE conducted by Govt of India. ",
    content: {
      isDisableNurture: true,
      assessment: `You will have the assessment of your abilities vis-a-vis other students who are your peers in future entrance/competitive exams`,
      awarenessAndVision: `You will develop an awareness towards your surroundings and start applying logical thinking.`,
    },
    // introductionVideo: {
    //   introductionDesc: "Student Evaluation Chart’ to students participating ",
    //   introductionVideoUrl: "https://youtu.be/3-WII5cPytg"
    // },
    // sampleVideo: {
    //   sampleDesc: "Student Evaluation Chart’ to students participating ",
    //   sampleVideoUrl: "https://youtu.be/3-WII5cPytg"
    // },
    level2: true,
    details: examData["ntex"],
  },
  rom: {
    name: "Regional Olympiad in Mathematics ",
    class: 6,
    date: "01-Sep-2024", //"23-10-2022"
    // phase1date: "12/26/2021",
    // phase2date: "02/20/2022",
    shortName: "ROM",
    exam_pattern: ROMPattern,
    exam_syllabus: ROMSyllabus,
    subject: "Knowledge",
    desc: "ROM- Regional Olympiad in Mathematics is aimed at bringing the most talented students from classes 6th to 10th standard from various schools across the country together in a friendly competition of the highest level. ROM provides a stimulus to pursue careers in Mathematics along with an experience into the realms of exciting intellectual challenges in Mathematics. This exam overlays the route for the most renowned IOQM conducted by HBCSE and NBHM leading to International Mathematical Olympiad. ",
    content: {
      isDisableNurture: true,
      assessment: `You will have the assessment of your abilities vis-a-vis other students who are your peers in future entrance/competitive exams`,
      awarenessAndVision: `You will develop an awareness towards your surroundings and start applying logical thinking.`,
    },
    // introductionVideo: {
    //   introductionDesc: "Student Evaluation Chart’ to students participating ",
    //   introductionVideoUrl: "https://youtu.be/3-WII5cPytg"
    // },
    // sampleVideo: {
    //   sampleDesc: "Student Evaluation Chart’ to students participating ",
    //   sampleVideoUrl: "https://youtu.be/3-WII5cPytg"
    // },
    level2: true,
    details: examData["rom"],
  },
};

export const getPadding = (width = 800) => {
  if (width <= 800 && width >= 750) {
    return {
      cPadding: "30%",
      mWidth: "600px",
    };
  } else if (width <= 750 && width >= 700) {
    return {
      cPadding: "28%",
      mWidth: "400px",
    };
  } else if (width <= 700 && width >= 650) {
    return {
      cPadding: "26%",
      mWidth: "400px",
    };
  } else if (width <= 650 && width >= 600) {
    return {
      cPadding: "24%",
      mWidth: "400px",
    };
  } else if (width <= 600 && width >= 550) {
    return {
      cPadding: "22%",
      mWidth: "400px",
    };
  } else if (width <= 550 && width >= 500) {
    return {
      cPadding: "20%",
      mWidth: "400px",
    };
  } else if (width <= 500 && width >= 450) {
    return {
      cPadding: "18%",
      mWidth: "400px",
    };
  } else if (width <= 450 && width >= 400) {
    return {
      cPadding: "14%",
      mWidth: "400px",
    };
  } else if (width <= 400 && width >= 350) {
    return {
      cPadding: "8%",
      mWidth: "400px",
    };
  } else if (width <= 350 && width >= 300) {
    return {
      cPadding: "12%",
      mWidth: "400px",
    };
  } else if (width <= 300) {
    return {
      cPadding: "12%",
      mWidth: "400px",
    };
  } else if (width <= 400) {
    return {
      cPadding: "8%",
      mWidth: "360px",
    };
  }
};
