import React, { useEffect } from "react";
import Header from "share/organisms/headers/features-header";
import {
  LargeDiv,
  Container,
  DescrTable,
  SubHeading,
  Div1,
  ValuesList,
  Title,
  MainHeadingWrapper,
  ContentWrapper,
  LeftDesc,
  Price,
  RightDesc,
  ExamName,
  Deatils,
  Class,
  Exam,
  // Registration,
  // RegImg,
  // RegContent,
  // RegTitle,
  // RegFooter,
  ICON,
  Benefits,
  Analytics,
  ExamNameMobile,
  TableWrapper,
  TableHeading,
  TableContent,
  HeadingWrapper,
  TableContentWrapper,
  // StyledSingleInfo,
  // Message,
  // MessageWrapper,
  // Heading,
  // HelpfulWrapper,
  // VideosWrapper,
  // IntroductionVideoWrapper,
  // SampleVideoWrapper,
  InfoWrapper,
  StyledButton,
  Hightlight,
} from "./styles";
// import IconSchool from "assets/exams/IconSchool.svg";
import school from "assets/school.svg";
import ISCO from "assets/isco_updated.svg";
import ISEO from "assets/iseo_updated.svg";
import ISKO from "assets/isko_updated.svg";
import ISMO from "assets/ismo_updated.svg";
import ISSO from "assets/isso_updated.svg";
import JOSA from "assets/updated_josa.svg";
import NTEX from "assets/ntex_updated.svg";
// import ROM from "assets/rom_updated.svg";
import ROM from "assets/updated2_rom.svg";
import { BsArrowRight } from "react-icons/bs";
import { useResize } from "share/hooks/useResize";

// import { formatDate } from "utils/utility/date";
import { FaRupeeSign } from "react-icons/fa";
import Footer from "student/LandingPage/Footer";
import {
  Logo,
  SchoolWrapper,
  Details,
  RegistrationButton,
} from "student/LandingPage/Exams/styles";
import Button from "share/atoms/Button";
// import Thumbnail from "assets/demo-thumbnail.jpg";
// import VideoPlay from "assets/video-play.svg";
// import PopUpModal from "share/molecules/popup-modal";
// import { ReactPlayerModal } from "share/molecules/player";
import Table from "share/organisms/details-table";
import GlobalContainer from "share/atoms/GlobalContainer";
import ForSchools from "student/LandingPage/ForSchools";

function ExamInfo(props) {
  // const examName = localStorage.getItem("examName");
  const examData = JSON.parse(localStorage.getItem("exam_data"));
  const examDetailsDataCheck = examData.content;
  let [assessment, isDisableNurture, awarenessAndVision] = [
    false,
    false,
    false,
  ];
  // const [introIsYoutubeVisible, setIntroIsYoutubeVisible] = useState(false);
  // const [sampleIsYoutubeVisible, setsampleIsYoutubeVisible] = useState(false);
  // const [introVideo, setIntroVideo] = useState(false);
  // const [sampleVideo, setSampleVideo] = useState(false);
  const EXAM_DATA = [
    {
      id: 1,
      heading: `Exposure`,
      desc: `Exposure to a larger pool of students from different schools and syllabus backgrounds`,
    },
    {
      id: 2,
      heading: `Rigorous Preparation`,
      desc: `Opportunity for all participating students to re-explore the subjects through arduous preparation `,
    },
    {
      id: 3,
      heading: `Revision of subject from Previous Academic Years`,
      desc: `Revision of text books of the previous class and the opportunity to appreciate the connection`,
    },
  ];

  if (examDetailsDataCheck) {
    assessment = examDetailsDataCheck.assessment;
    isDisableNurture = examDetailsDataCheck.isDisableNurture;
    awarenessAndVision = examDetailsDataCheck.awarenessAndVision;
  }

  EXAM_DATA.push({
    id: 4,
    heading: `Awareness and Vision`,
    desc: `${
      awarenessAndVision
        ? awarenessAndVision
        : `Chance to win scholarships and unique opportunities to meet the professionals in the field of ${examData.subject} by being a part of the nurture programs`
    }`,
  });
  // these two exams have nurture program ismo isso
  if (!isDisableNurture) {
    EXAM_DATA.push({
      id: 5,
      heading: `Nurture Programs`,
      desc: `Chance to win scholarships and unique opportunities to meet the professionals in the field of ${examData.subject} by being a part of the nurture programs`,
    });
  }
  EXAM_DATA.push({
    id: 6,
    heading: `Assessment`,
    desc: `${
      assessment
        ? assessment
        : `You will have the assessment of your abilities vis-a-vis other students who are your peers in future entrance/competitive exams like IIT-JEE, NEET, SAT, Olympiads, KVPY, NTSE, CAT, GRE, GMAT and many such exams.`
    }`,
  });

  const gotoSchoolReg = () => {
    props.history.push("/school/registration");
  };

  const gotoSchoolLogin = () => {
    props.history.push("/school/login");
  };

  const navigateToStudentRegistration = () => {
    if (window?.innerWidth > 480) props.history.push("/student/registration");
    else window.open(process.env.REACT_APP_PLAY_STORE_URL);
  };

  // const handleIntroClose = () => {
  //   setIntroIsYoutubeVisible(false);
  // };

  // const handleSampleClose = () => {
  //   setsampleIsYoutubeVisible(false);
  // };

  // const handleIntro = () => {
  //   setIntroIsYoutubeVisible(true);
  //   setIntroVideo({
  //     content_url: examData.introductionVideo.introductionVideoUrl,
  //     source: "youtube",
  //   });
  // };

  // const handleSample = () => {
  //   setsampleIsYoutubeVisible(true);
  //   setSampleVideo({
  //     content_url: examData.sampleVideo.sampleVideoUrl,
  //     source: "youtube",
  //   });
  // };

  const getIcon = (data) => {
    switch (data) {
      case "ISCO":
        return ISCO;
      case "ISEO":
        return ISEO;
      case "ISKO":
        return ISKO;
      case "ISMO":
        return ISMO;
      case "ISSO":
        return ISSO;
      case "NTEX":
        return NTEX;
      case "ROM":
        return ROM;
      case "JOSA":
        return JOSA;
      default:
        break;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { width } = useResize();
  return (
    <>
      <Header {...props} />
      <GlobalContainer
        style={{
          backgroundColor: "#f9f9ff",
          paddingBottom: "72px",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            padding: width < 767 ? "0px 16px 0px 16px" : "0px 0px 0px 0px",
            boxShadow: "0 16px 32px 0 rgba(221, 230, 237, 0.4)",
            borderRadius: "6px",
            backgroundColor: "#f9f9ff",
          }}
        >
          <Hightlight>
            <span>6+ Years</span>
            <span>2500+ Schools</span>
            <span>75+ lakhs Assessments</span>
            {/* <span>8 Olympiads</span> */}
          </Hightlight>
          <>
            <>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "6px",
                  padding: "0px 0px 62px 0px",
                  margin: "0px 16px 0px `16px",
                }}
              >
                <MainHeadingWrapper>
                  <ICON src={getIcon(examData.shortName)} />
                  <ExamNameMobile>{`${examData.name} (${examData.shortName})`}</ExamNameMobile>
                  <ContentWrapper>
                    <LeftDesc>
                      <ExamName>{`${examData.name} `}</ExamName>
                      <Deatils>
                        <Class>
                          <b>Class:&nbsp;&nbsp;&nbsp; </b>{" "}
                          <p>
                            {examData.class}
                            <sup>{examData.class < 6 ? "st" : "th"}</sup> - 10
                            <sup>th</sup>
                          </p>
                        </Class>
                        <Exam>
                          <b>Exam Date:&nbsp;&nbsp;&nbsp;&nbsp;</b>
                          <p>{examData.date}</p>
                          {/* <StyledSingleInfo>
                      <div className="singleinfo-details left">Phase-1</div>
                      <div className="singleinfo-details left ">
                        {formatDate(examData.phase1date, "DD-MMM-YYYY")}
                      </div>
                    </StyledSingleInfo>
                    <StyledSingleInfo border>
                      <div className="singleinfo-details">Phase-2</div>
                      <div className="singleinfo-details">
                        {formatDate(examData.phase2date, "DD-MMM-YYYY")}
                      </div>
                    </StyledSingleInfo> */}
                        </Exam>
                      </Deatils>
                      {/* <MessageWrapper >
                  {examData.shortName === "ISMO" && <Message>*Phase 2 Exam for class 10 students is on
                    <span className="date">25th Jan 2022.</span></Message>}
                </MessageWrapper> */}
                    </LeftDesc>
                    <RightDesc>
                      <b>Price</b>
                      <Price>
                        <h1 className="price">
                          {" "}
                          <FaRupeeSign fontSize="20" />
                          <span>300</span>
                        </h1>
                      </Price>
                      <StyledButton onClick={navigateToStudentRegistration}>
                        {/* Coming Soon &nbsp; */}
                        Buy Now &nbsp;
                        <BsArrowRight />
                      </StyledButton>
                    </RightDesc>
                  </ContentWrapper>
                </MainHeadingWrapper>
                <DescrTable>
                  <b>Description</b>
                  <p>{examData.desc}</p>
                  {/* {examData.level2 && (
              <InfoWrapper>
                <span className="info">*Info</span> : Secure top marks in this exam and get a chance to attempt the Level 2 exam, where more exciting prizes can be won.
              </InfoWrapper>
            )} */}

                  <Table>
                    <Table.Header>
                      <Table.HeaderItem style={{ width: "200px" }}>
                        Class
                      </Table.HeaderItem>
                      <Table.HeaderItem>Exam Pattern</Table.HeaderItem>
                      <Table.HeaderItem>Exam Syllabus</Table.HeaderItem>
                    </Table.Header>
                    {examData?.details?.map((item, index) => (
                      <Table.Body key={index}>
                        <Table.BodyItem style={{ width: "200px" }}>
                          {item.class}
                        </Table.BodyItem>
                        <Table.BodyItem href={item.pattern}>
                          View PDF
                        </Table.BodyItem>
                        <Table.BodyItem href={item.syllabus}>
                          View PDF
                        </Table.BodyItem>
                      </Table.Body>
                    ))}
                  </Table>
                </DescrTable>
                {/* ========================= */}
                {/* <>
            {introIsYoutubeVisible && (
              <PopUpModal onClose={handleIntroClose} centered margin={"0px"}>
                <ReactPlayerModal data={introVideo} />
              </PopUpModal>
            )}

            {sampleIsYoutubeVisible && (
              <PopUpModal onClose={handleSampleClose} centered margin={"0px"}>
                <ReactPlayerModal data={sampleVideo} />
              </PopUpModal>
            )}
            <HelpfulWrapper>
              <Heading>Helpful Video</Heading>
              <VideosWrapper>
                {examData.introductionVideo && (
                  <IntroductionVideoWrapper>
                    <div>
                      <h3 className="intro">Introduction</h3>
                      <p className="para">
                        {examData.introductionVideo.introductionDesc}{" "}
                      </p>
                      <p className="time">2mins</p>
                    </div>
                    <div className="video-container" onClick={handleIntro}>
                      <img src={Thumbnail} className="thumbnail" />
                      <img src={VideoPlay} className="play-button" />

                    </div>
                  </IntroductionVideoWrapper>
                )}
                {examData.sampleVideo && (
                  <SampleVideoWrapper>
                    <div>
                      <h3 className="intro">Sample Video</h3>
                      <p className="para">{examData.sampleVideo.sampleDesc} </p>
                      <p className="time">2mins</p>
                    </div>
                    <div className="video-container" onClick={handleSample}>
                      <img src={Thumbnail} className="thumbnail" />
                      <img src={VideoPlay} className="play-button" />
                    </div>
                  </SampleVideoWrapper>
                )}
              </VideosWrapper>
            </HelpfulWrapper>
          </> */}

                {/* ============================ */}

                <SubHeading>Student Analytics</SubHeading>
                <Analytics>
                  <Div1>
                    We provide the ‘Student Evaluation Chart’ to students
                    participating in &nbsp;
                    {examData.shortName} with the following metrics:
                  </Div1>
                  <Div1>
                    <ValuesList>
                      <li>Absolute Score </li>
                      <li>Percentile Score</li>
                      <li>Rank/State Rank/National Rank</li>
                      <li>
                        Grade: High Distinction (HD)/Distinction (D)/
                        Satisfactory(S)/ Remedial (R)
                      </li>
                      <li>Category and Sub Category wise Response report </li>
                      <li>Difficulty Level Response report</li>
                      <li>5 Qs Analysis</li>
                      <li> Generic Inferences for Improvement </li>
                      <li>Graphical Analysis Tools</li>
                    </ValuesList>
                  </Div1>
                </Analytics>
                <SubHeading>{examData.shortName} Benefits</SubHeading>
                <Benefits>
                  {EXAM_DATA.map((data) => (
                    <>
                      <Title>{data.heading}</Title>
                      <Div1>{data.desc}</Div1>
                    </>
                  ))}
                </Benefits>
              </div>
            </>
          </>
        </div>
      </GlobalContainer>
      <GlobalContainer
        style={{
          backgroundColor: "#f9f9ff",
          paddingBottom: "48px",
        }}
      >
        <div
          style={{
            padding: width < 767 ? "0px 16px 0px 16px" : "0px",
            boxShadow: "0 16px 32px 0 rgba(221, 230, 237, 0.4)",
            borderRadius: "6px",
          }}
        >
          <ForSchools />
        </div>
      </GlobalContainer>

      <Footer />
    </>
  );
}

export default ExamInfo;
